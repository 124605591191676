import { GetStudioSessionPayload, GetStudioSessionResponse } from "../types";
import { LocalStorageManager } from "@/shared/lib/localStorageManager";
import IdTokenManager from "./idTokenManager";
import { ORG_STORE_KEY } from "@/configs/constants";

export const STUDIO_TOKEN_EXP_STORAGE_KEY = "nextRefreshAt";

export class StudioTokenManager {
  static isTokenValid(): boolean {
    const tokenExpiryTime = this.getTokenExpiry();
    if (!tokenExpiryTime || !this.isTokenExpired(tokenExpiryTime)) {
      return false;
    }
    return true;
  }

  static getTokenExpiry(): number {
    return Number(LocalStorageManager?.get(STUDIO_TOKEN_EXP_STORAGE_KEY));
  }

  private static isTokenExpired(tokenExpiryTime: number): boolean {
    const timeNow = Math.floor(Date.now() / 1000);
    return timeNow < tokenExpiryTime;
  }

  static async fetchStudioToken() {
    const idToken = await IdTokenManager.getIdToken();
    const orgId = LocalStorageManager.get(ORG_STORE_KEY)?.state?.currentOrg;

    const response = await this.getStudioJwts({
      idToken,
      orgId,
    });

    if (response.nextRefreshAt) {
      LocalStorageManager.set(
        STUDIO_TOKEN_EXP_STORAGE_KEY,
        response.nextRefreshAt
      );
    }

    return response;
  }

  private static async getStudioJwts(
    payload: GetStudioSessionPayload
  ): Promise<GetStudioSessionResponse> {
    try {
      const { idToken, orgId } = payload;
      const response = await fetch(
        `/api/auth${orgId ? `?orgId=${orgId}` : ""}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch JWTs");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching JWTs:", error);
      throw error;
    }
  }
}

export default StudioTokenManager;
