export async function logout() {
  const resp = await fetch(
    `${process.env.NEXT_PUBLIC_AUTH_SERVICE_DOMAIN}/api/v1/user/logout`,
    {
      method: "POST",
      credentials: "include",
    }
  );

  return resp.json();
}
