"use client";
export class LocalStorageManager {
  private static parseStoredData(data: string): any {
    const trimmedData = data.replace(/^"(.*)"$/, "$1");
    try {
      const parsedData = JSON.parse(trimmedData);
      if (
        typeof parsedData === "boolean" ||
        typeof parsedData === "number" ||
        typeof parsedData === "object"
      ) {
        return parsedData;
      }
    } catch (e) {
      // If parsing fails, it's a string
      return trimmedData;
    }
    // Return as string if it's not a valid JSON or is a JSON string
    return trimmedData;
  }

  public static get(storageKey: string) {
    try {
      const data = localStorage.getItem(storageKey);
      return data ? LocalStorageManager.parseStoredData(data) : null;
    } catch (error) {
      console.error("Error reading token from storage:", error);
      return null;
    }
  }

  public static set(storageKey: string, storageValue: any) {
    try {
      localStorage.setItem(storageKey, JSON.stringify(storageValue));
    } catch (error) {
      console.error("Error saving token to storage:", error);
    }
  }

  public static clear(storageKey: string) {
    try {
      localStorage.removeItem(storageKey);
    } catch (error) {
      console.error("Error clearing token from storage:", error);
    }
  }
}
