"use client";
import { useEffect } from "react";
import { useSearchParams, useRouter } from "next/navigation";
import { LoadingScreen } from "@/shared/contexts/LoadingContext/components/LoadingScreen";
import { useAuth } from "@/shared/hooks/useAuth";
import { LocalStorageManager } from "@/shared/lib/localStorageManager";
import { ORG_STORE_KEY } from "@/configs/constants";

export default function ReAuthPage() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { getNewStudioToken } = useAuth();

  useEffect(() => {
    function handleAuth() {
      try {
        const redirectParam = searchParams.get("redirect");
        const decodedPath = decodeURIComponent(redirectParam as string);

        // todo: useOrgStore
        const currentOrg =
          LocalStorageManager.get(ORG_STORE_KEY)?.state?.currentOrg;

        const redirectTo = currentOrg ? decodedPath : "/org";

        getNewStudioToken(redirectTo);
      } catch (error) {
        console.error("Failed to get new studio token:", error);
      }
    }

    handleAuth();
  }, [getNewStudioToken, router, searchParams]);

  return <LoadingScreen />;
}
