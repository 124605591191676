"use client";
import { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useInterval } from "usehooks-ts";
import { StudioTokenManager } from "./lib";
import { AUTH_REFRESH_INTERVAL, ORG_STORE_KEY } from "@/configs/constants";
import { LocalStorageManager } from "@/shared/lib/localStorageManager";

const STUDIO_TOKEN_QUERY_KEY = ["getStudioToken"];

// todo: refresh test
const REFRESH_INTERVAL = AUTH_REFRESH_INTERVAL * 1000; // 25 minutes in milliseconds

export const useStudioToken = () => {
  const queryClient = useQueryClient();

  const [isFetchEnabled, setIsFetchEnabled] = useState(true);
  const [refetchInterval, setRefetchInterval] =
    useState<number>(REFRESH_INTERVAL);
  const [isLoading, setIsLoading] = useState(true);

  const updateFetchStatus = useCallback(async () => {
    const expTimestamp = StudioTokenManager.getTokenExpiry() * 1000;
    if (expTimestamp > 0) {
      setRefetchInterval(expTimestamp - Date.now());
    } else {
      const storedOrgs = LocalStorageManager.get(ORG_STORE_KEY)?.state?.allOrgs;
      if (!storedOrgs) {
        queryClient.invalidateQueries({ queryKey: STUDIO_TOKEN_QUERY_KEY });
      }
    }
    setIsLoading(false);
  }, [queryClient]);

  useEffect(() => {
    updateFetchStatus();
  }, [updateFetchStatus]);

  useInterval(() => {
    setIsFetchEnabled(!StudioTokenManager?.isTokenValid());
  }, refetchInterval);

  const query = useQuery({
    queryKey: STUDIO_TOKEN_QUERY_KEY,
    queryFn: async () => {
      try {
        return StudioTokenManager.fetchStudioToken();
      } catch (error) {
        console.error("Error fetching studio token: ", error);
      } finally {
        setIsLoading(false);
      }
    },
    initialData: null,
    enabled: isFetchEnabled,
    refetchInterval: refetchInterval,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: 3,
    gcTime: 5000,
  });

  return {
    refetch: query.refetch,
    data: query.data,
    isPending: query.isPending || isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    error: query.error,
  };
};
