"use client";
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useStore } from "zustand";
import { useRouter } from "next/navigation";
import appConfig from "@/configs";
import {
  GetAuthSession200AllOrgsItem,
  GetAuthSession200SessionUser,
} from "@/shared/api/models";
import { logout as logout } from "./lib/logout";
import { useStudioToken } from "./hooks/useStudioToken";
import { createUserStore } from "./store";

const { authServiceFeUrlWithRedirect } = appConfig;

interface AuthContextValue {
  user: GetAuthSession200SessionUser | null;
  allOrgs: GetAuthSession200AllOrgsItem[];
  isLoading: boolean;
  logout: () => void;
  getNewStudioToken: (redirect?: string) => void;
}

export const AuthContext = createContext<AuthContextValue>({
  user: null,
  allOrgs: [],
  isLoading: true,
  logout: () => {},
  getNewStudioToken: () => {},
});

export const AuthContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();

  const [store] = useState(createUserStore);
  const { user: storedUser, setUser } = useStore(store);

  const {
    data: authSession,
    isPending: isAuthSessionPending,
    isSuccess: isAuthSessionSuccess,
    refetch: refetchAuthSession,
  } = useStudioToken();

  useEffect(() => {
    if (!isAuthSessionSuccess) return;

    const sessionUser = authSession?.session?.user;
    if (sessionUser) {
      setUser(sessionUser);
    }
  }, [isAuthSessionSuccess, storedUser, authSession, setUser]);

  const getNewStudioToken = useCallback(
    async (redirect = "/channels") => {
      await refetchAuthSession();
      router.push(redirect);
      router.refresh();
    },
    [router, refetchAuthSession]
  );

  return (
    <AuthContext.Provider
      value={{
        logout: async () => {
          await logout();
          router.push(authServiceFeUrlWithRedirect);
        },
        user: storedUser,
        allOrgs: authSession?.allOrgs || [],

        getNewStudioToken,
        isLoading: isAuthSessionPending,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
