import { create } from "zustand";
import { persist } from "zustand/middleware";
import { GetAuthSession200SessionUser } from "@/shared/api/models";
import { USER_STORE_KEY } from "@/configs/constants";
import { UserStore } from "./types";

export const createUserStore = () =>
  create<UserStore>()(
    persist(
      (set) => ({
        user: null,
        setUser: (user: GetAuthSession200SessionUser) => set({ user }),
      }),
      {
        name: USER_STORE_KEY,
        partialize: (state) => ({
          user: state.user,
        }),
      }
    )
  );
