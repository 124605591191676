import { LoadingSpinner } from "@/shared/components/ui/LoadingSpinner";

export function LoadingScreen() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
      <div className="h-16 w-16">
        <LoadingSpinner size={16} />
      </div>
    </div>
  );
}
